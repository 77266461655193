<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :maskClosable="false"
      :width="1200"
      :height="700"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <div class="wrap thewidth">
      <div class="step_box">
        <div class="step_box_head" v-if="level>1">
          <div class="step_menu clearfix">
            <ul>
              <li :class="{checked:currentStep>=0}" id="auto_brand" @click="changeStep(0)"><span>1. 品牌选择</span></li>
              <li v-if="level>1" :class="{checked:currentStep>=1}" class="" id="auto_vehicle" @click="changeStep(1)">
                <span>2. 车系选择</span></li>
              <li v-if="level>2" :class="{checked:currentStep>=2}" class="" id="auto_model"><span>3. 车型选择</span></li>
            </ul>
          </div>
        </div>
        <car-brands v-show="currentStep==0" ref="carBrands" @selectBrand="selectBrand"
                    :multiple="level===1&&multiple"
                    :selectMode="true"
        ></car-brands>
        <car-series v-if="level>1" v-show="currentStep==1" ref="carSeries" @selectSeries="selectSeries"
                    :multiple="level==2&&multiple"
                    :selectMode="true"/>
        <car-models v-if="level>2" v-show="currentStep==2" ref="carModels" :multiple="level==3&&multiple"
                    :selectMode="true"/>
      </div>
    </div>
  </a-modal>
</template>

<script>
import CarBrands from "@/components/car/CarBrands";
import CarSeries from "@/components/car/CarSeries";
import CarModels from "@/components/car/CarModels";

export default {
  name: "SelectBrandModal",
  components: {CarBrands, CarSeries, CarModels},
  props: {},
  data() {
    return {
      currentStep: 0,
      visible: false,
      level: 3, //选择级别：1--品牌，2--车系，3--车型
      multiple: false, //单选，多选
      selected: undefined
    }
  }, computed: {
    title() {
      if (this.level == 1) {
        return '选择品牌'
      } else if (this.level == 2) {
        return '选择车系'
      } else if (this.level == 3) {
        return '选择车型'
      }
    }
  },
  mounted() {

  },
  methods: {
    showModal(config = {level: 3, multiple: false}) {
      this.level = config.level || 3
      this.multiple = config.multiple || false
      this.currentStep = 0;
      this.selected = []
      this.visible = true
    },
    handleOk() {
      if (!this.selected || this.selected.length == 0) {
        this.$message.warn('请先' + this.title)
        return
      }
      this.visible = false;
      this.$emit('success', this.selected)
    },
    changeStep(step) {
      if (this.currentStep > step) {
        this.currentStep = step
      }
      if (this.currentStep == 0) {
        this.$refs.carBrands.resetIndex()
      }
    },
    async selectBrand(brand) {
      if (this.level > 1) {
        if (Array.isArray(brand)) {
          brand = brand[0]
        }
        await this.$refs.carSeries.brandChanged(brand)
        this.currentStep = 1
      } else {
        this.selected = brand;
      }
    },
    async selectSeries(series) {
      if (this.level > 2) {
        if (Array.isArray(series)) {
          series = series[0]
        }
        await this.$refs.carModels.seriesChanged(series)
        this.currentStep = 2
      } else {
        this.selected = series;
      }
    },
    async selectModels(models) {
      this.selected = models;
    }
  }

}
</script>

<style scoped lang="less">

.step_box {
  margin: 0px auto;
  border: 1px #E5E5E5 solid;


  .step_box_head {
    border-bottom: 1px #E5E5E5 solid;
    color: #666;
    font-size: 16px;
    font-weight: 100;
    padding: 15px 40px 15px 0;
    background: #F5F5F5;
  }

  .step_menu {
    ul li {
      float: left;
      margin-right: 20px;
      border: 0 none;
      list-style: none;
      cursor: pointer;
    }

    ul li span {
      display: block;
      background: url(/static/images/step_menu.png) left bottom no-repeat;
      font-size: 14px;
      width: 146px;
      height: 35px;
      line-height: 35px;
      padding-left: 30px;
    }

    ul li.checked span {
      background: url(/static/images/step_menu.png) left top no-repeat;
      color: #FFF;
      border: 0 none;
    }
  }
}

/deep/ .ant-modal-body {
  padding: 0 0px;
}
</style>
