<template>
  <div class="biz-form">
    <div class="biz-row">
      <div class="label">主营产品类型</div>
      <div class="value">
        <a-radio-group v-model="form.bizType">
          <a-radio :value="1">
            全车件
          </a-radio>
          <a-radio :value="2">
            单项件
          </a-radio>
        </a-radio-group>
      </div>
    </div>
    <div class="biz-row">
      <div class="label">{{ form.bizType === 1 ? '主营车型' : '单项件关键字' }}</div>
      <div class="value">
        <a-button size="small" type="primary" @click="addKeyword" icon="plus">增加</a-button>
      </div>
    </div>
    <div class="biz-row tag-list">
      <template v-for="(tag, index) in keywordList">
        <a-tag :key="tag" :closable="true" @close="() => handleDelete(index)" color="pink">
          {{ tag }}
        </a-tag>
      </template>
    </div>
    <div v-if="form.bizType===2" class="biz-row">
      <div style="color:#999;">
        单项件指主营某一类产品，比如有的只卖刹车片，但是大部分车型的刹车片都有买，有的只卖减震器。
        <br>请输入您公司所做产品的关键词，系统会根据关键词转发询价单。请尽量通盖客户输入内容，如您主营空气滤洁器，那么您至少需要输入2个关键词，[空气滤清器] [空滤]。
        <div>

        </div>
      </div>
    </div>
    <div class="biz-row" style="margin-left:100px;">
      <a-button type="primary" @click="saveBizInfo" :loading="loading" icon="save">保存修改</a-button>
    </div>
    <select-brand-modal ref="selectBrand" @success="brandSelected"/>
  </div>
</template>

<script>
import SelectBrandModal from "@/components/car/SelectBrandModal";

export default {
  name: "BizCarBrandSettings",
  components: {SelectBrandModal},
  props: {
    companyInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      form: {
        bizType: 1,
        bizBrand: ''
      },
      loading:false
    }
  },
  computed: {
    keywordList: {
      get() {
        let bizBrand = this.companyInfo.bizBrand;
        if (!bizBrand) {
          return []
        }
        return JSON.parse(bizBrand);
      },
      set(val) {
        this.companyInfo.bizBrand = JSON.stringify(val)
      }
    }
  },
  methods: {
    handleDelete(index) {
      this.keywordList.splice(index, 1)
    },
    addKeyword() {
      if (this.form.bizType == 1) {
        this.$refs.selectBrand.showModal({level: 2, multiple: true})
      }
    },
    brandSelected(brands) {
      console.log(brands)
      let list = []
      brands.forEach(v => {
        let name = v.brandName + '/' + v.factoryName + '/' + v.seriesName
        if (!this.keywordList.includes(name)) {
          this.keywordList.push(name)
        }
      })
//       list = Array.from(new Set(this.keywordList.concat(list)))
// console.log(this.keywordList,list)
//       this.keywordList = [].concat(list);
//       console.log(this.keywordList,list)

      this.$forceUpdate()
    },
    saveBizInfo() {

    }
  }
}
</script>

<style scoped lang="less">
.biz-form {
  .biz-row {
    display: flex;
    align-items: center;
    padding: 10px 20px;

    .label {
      margin-right: 20px;
      width: 100px;
      text-align: right;
    }

    .value {

    }

  }

  .tag-list {
    border: 1px solid #EEE;
    margin-left: 120px;
    max-width: 1000px;
  }
}
</style>
