import { render, staticRenderFns } from "./BizCarBrandSettings.vue?vue&type=template&id=50012011&scoped=true&"
import script from "./BizCarBrandSettings.vue?vue&type=script&lang=js&"
export * from "./BizCarBrandSettings.vue?vue&type=script&lang=js&"
import style0 from "./BizCarBrandSettings.vue?vue&type=style&index=0&id=50012011&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50012011",
  null
  
)

export default component.exports